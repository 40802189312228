import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import SocialWrapper from "../../components/SocialWrapper";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs.js";;
import Banner from "../../components/Banner";
import EmailSignupSimplePlugin from "../../components/EmailSignupSimplePlugin";
import { experienceYears } from "../../components/Helpers";

const StyledMiddle = styled.div`
  margin: 0vmin 1vmin;
  .container {
    margin: 7vmin 0vmin;
    display: flex;
  }

  h1 {
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
  }

  .content {
    width: 60%;
    margin-right: 1rem;
  }

  .image-wrapper {
    margin: 0rem 0rem auto 0rem;
    width: 30%;

    & .gatsby-image-wrapper {
      box-shadow: 0px 0px 3px var(--foreground);
      margin-bottom: 1rem;
    }
  }
  @media all and (max-width: 540px) {
    justify-content: center;
    .container {
      flex-direction: column-reverse;
    }

    .content {
      width: 100%;
      margin-right: none;
    }

    .image-wrapper {
      margin: 1rem auto 4rem auto;
      width: 60%;
    }
  }
`;

const StyledSecondaryContent = styled.div`
  grid-area: secondary;
  background: orange;
  display: flex;

  .card {
    border-top: 1px solid var(--foreground);
    &:first-child {
      border-right: 1px solid var(--foreground);
    }
  }

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
`;

// Breadcrumb data

const breadCrumbLevels = {
  Home: "/de",
  "Über mich": "/de/ueber-mich"
};

// Hreflang data

const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/about"
);

const Uebermich = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO title="Über Matthias Kupperschmidt" lang="de" alternateLangs={alternateLangs} />
      <MainContent maxWidth="700">
        <StyledMiddle>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
          <div className="container">
            <div className="content">
              <H as="h1">Hi, Ich bin Matthias</H>
              <p>
                Ich optimiere Webseiten für Google's Suchergebnisse und baue außerdem Analytics Setups, um
                Nutzeraktivität zu tracken.
              </p>
              <p>
                Ich bin Deutscher, wohne jedoch in Kopenhagen, Dänemark. Ich habe für {experienceYears} Jahre in mehreren digitalen
                Agenturen gearbeitet, bevor ich begonnen habe als Freelance SEO und Analytics Consultant zu arbeiten.
              </p>
              <p>
                Persönlichkeitstests bescheinigen mir immer das selbe: sehr analytisch, rational und strukturiert und
                dabei zielorientiert und pragmatisch.
              </p>
              <p>
                Mit 14 habe ich meine erste Webseite gebaut. Damals, um Videofilme mit Schulkameraden zu tauschen.
                Eine Internetseite war das einfachste, um meine ständig wachsende Liste von Filmen zu teilen. Mit 18
                habe ich meinen ersten Webshop für Tischfussball gebaut ("Kickerkult"), während ich am Wochenende
                möchtegern-professionel in Berlin und in Deutschland auf Kickertunieren unterwegs war. Während der
                Arbeit mit dem Webshop, Email Newslettern und SEO, habe ich mich mehr und mehr für das Internet
                begeistert, so dass ich mein Berufsleben darauf konzentrieren wollte.
              </p>
              <p>
                Heute helfe ich Firmen jeglicher Größe in Googles Suchergebnissen nach vorne zu kommen und außerdem
                die Interaktionen der Besucher zu tracken - egal wo in der Welt sich die Firmen befinden.
              </p>
              <p>
                Zusätzlich schreibe ich über SEO und Analytics Themen in meinem <Link to="/de/blog">Blog</Link>.
              </p>
              <p>
                Ich bin stark davon Überzeugt, dass man nichtmehr in “die große Stadt” ziehen muss, um einen guten
                Karrieregang zu erreichen. Moderne Firmen, Fachkräfte und Gleichgesinnte finden sich online um
                zusammen zu arbeiten, ungeachtet der geografischen Distanz.
              </p>
              <p>Welche Stadt ist das nächste Silicon Valley? - Deine!</p>
            </div>
            <div className="image-wrapper">
              <Img clickable="false" src="about/matthias-kupperschmidt-about-me-portrait.jpeg" alt="matthias kupperschmidt" />
              <SocialWrapper align="center" twitter="mattzipan" linkedin="matthiaskupperschmidt" github="mattzipan" />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
      <StyledSecondaryContent>
        <Banner>
          <div className="banner__header serif headline">Let's stay in touch!</div>
          <div className="serif">
            Don’t worry I will barely email you. Signup here to be sure to hear when I have something important to
            share.
          </div>
          <div className="emailwrapper">
            <EmailSignupSimplePlugin />
          </div>
        </Banner>
        <Banner>
          {/* <div className="banner__header serif headline">
                “I need help with SEO or analytics”
              </div>
              <div className="serif">
                Don’t worry I will barely email you. Signup here to be sure to
                hear when I have something important to share.
              </div> */}
        </Banner>
      </StyledSecondaryContent>
    </React.Fragment>

  </Layout>
);

export default React.memo(Uebermich);
